
import React from "react";

import Link from "components/Link";
import { languageKey } from "components/helper";
import Slider from "components/Slider";


const NewsSlider = ({
  news,
}) => {
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    rtl: (languageKey === 'ar'),
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
        }
      },
    ],
  };

  return (
    <div
    style={{
      width: '100%',
    }}>
      <Slider settings={settings}>
        {news.map(({ node: {
          fields,
          frontmatter,
        } }, i) => (
          <div className="slide-box">
            <div
              className="news-box ss_eight ss_box_wbg mx-3"
              style={{
                backgroundImage: `url(${frontmatter.wrapper})`
              }}
            >
              {/* <Link to={fields.slug}> */}
                {/* <img className="img-fluid" src={frontmatter.wrapper} alt="wrapper" title="wrapper" /> */}
              {/* </Link> */}
              <div className="ss_product_content ss_box_bg">	
                <h2>
                  <Link slug={fields.slug}>{frontmatter.title}</Link>
                  <span><Link to={fields.slug}>26 July 2019</Link></span>
                </h2>
                <p>{frontmatter.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default NewsSlider;