import { useStaticQuery, graphql } from "gatsby"
import { languageKey } from "components/helper";


export const PostsQuery = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {templateType: {eq: "posts"}}} limit: 6) {
          edges {
            node {
              id
              fields {
                lang
                slug
              }
              frontmatter {
                date
                title
                description
                wrapper
              }
            }
          }
        }
      }
    `
  )

  const getbyLanguage = allMarkdownRemark.edges.filter(
    data => data.node.fields.lang === languageKey);

  return {
    data: getbyLanguage || [],
  };

}