import React from 'react'
import classNames from 'classnames';
import NewsSlider from './Slider';
import { PostsQuery } from './query';
import i18nx from 'components/locale/i18nx';

const LatestNews = ({
  noPT
}) => {
  const { data } = PostsQuery();
  return (
    <section className={classNames("ss_section_eight ss_section_sec_bg spacer_bottom", !noPT && 'pt-0')}>
      <div className="container-fluid"> 
        <h3 className="ss_h3_center text-center">{i18nx('blog_posts')}</h3>
        <h1 className="text-center mb-5">{i18nx('latest_news')}</h1>
        <div className="">
          <div className="d-flex" >
            <NewsSlider news={data} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default LatestNews;
