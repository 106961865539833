import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import Layout from 'components/layout';
import Header from 'components/Header';
import SEO from 'components/seo';
import Link from 'components/Link';
import i18nx from 'components/locale/i18nx';
import LatestNews from 'components/Sections/LatestNews/index';


const SinglePost = ({
  data: {
    markdownRemark: { html, frontmatter },
  },
  pageContext,
}) => {
  return (
    <Layout>
      <SEO
        lang={pageContext.lang}
        title={frontmatter.title}
      />
      <Header lang={pageContext.lang}>
        <div className="ss_breadcrumb text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h1>{frontmatter.title}</h1>
                <ul>
                  <li><Link to="/">{i18nx('home')}</Link><span> / {i18nx('blog')}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <section className="ss_blog spacer_bottom spacer_top">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="ss_blog_left">
                <div className="ss_blog_img">
                  <img className="w-100 img-fluid" src={frontmatter.wrapper} alt="wrapper" title="wrapper" />
                </div>	
                <div className="ss_user flex justify-content-between mt-3">
                  <h2>{frontmatter.title}</h2>
                  <a><i className="far fa-calendar-alt mx-2" />{moment(frontmatter.date).format('LL')}</a>
                </div>
                <div className="blog-post" dangerouslySetInnerHTML={{ __html: html }} />
                <div className="ss_border" />
                <div className="row">
                  <div className="col-md-8">
                    <div className="post_head">
                      <h2>{i18nx('related_tags')}</h2>
                      <ul className="ss_tags">
                        {frontmatter.tags &&
                        frontmatter.tags.map((tag, i) => (
                          <li><a>{tag}</a></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4" />
                </div>
                <div className="ss_border" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <LatestNews noPT />
    </Layout>
  )
}

export const pageQuery = graphql`
  query Post($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        lang
        templateType
        wrapper
        date
        description
        tags
      }
    }
  }
`
export default SinglePost;
